import { RefObject, useEffect, useMemo, useState } from 'react';
import DataModelRegistry from '../DataModelRegistry';
import { DataModel } from '../../../dataModel/model/DataModel';
import CreateNewColumnModal from '../../../../components/CreateNewColumnModal';
import CreateNewOptionModal from '../../../../components/CreateNewOptionModal';
import { DATATYPE } from '../../../dataType';
import { Subject } from 'rxjs';
import HotTable from '@handsontable/react';
import { DropdownOptionType } from '../../../dataModel/columnsAPI';
import useColumnViewModel from './columnViewModel';
import useOptionViewModel from './optionViewModel';
import { CustomOptionUIObservable } from '../customOption';
import CategoryDataModel from '../../../dataModel/model/CategoryDataModel';
import { CustomColumnUIObservable } from '../customColumns';
import { useTheme } from '../../../../theme';

type CustomColumnAndOptionProps = {
  dataModelRegistry: DataModelRegistry;
  onAddColumn: (
    columnName: string,
    columnType: DATATYPE,
    dateFormat?: string
  ) => void;
  onAddOption: (
    dataModel: CategoryDataModel,
    optionName: string,
    optionType: DropdownOptionType
  ) => void;
  onEditColumn: (dataModel: DataModel, optionName: string) => void;
  customColumnUIObservable: Subject<CustomColumnUIObservable>;
  customOptionUIObservable: Subject<CustomOptionUIObservable>;
  hotInstance: RefObject<HotTable>;
};

const CustomColumnAndOption = ({
  dataModelRegistry,
  onAddColumn,
  onAddOption,
  customColumnUIObservable,
  customOptionUIObservable,
  hotInstance,
  onEditColumn,
}: CustomColumnAndOptionProps) => {
  const [dataModels, setDataModels] = useState<DataModel[]>(
    dataModelRegistry.getDataModels()
  );
  const {
    isCustomColumnOpen,
    onCustomColumnClose,
    columnInitialValues,
    typeCustomColumn,
    editingDataModel,
  } = useColumnViewModel({
    customColumnUIObservable,
    hotInstance,
  });
  const {
    isAddOptionOpen,
    onAddOptionClose,
    addingOptionDataModel,
    optionInitialValues,
  } = useOptionViewModel({
    customOptionUIObservable,
    hotInstance,
  });

  useEffect(() => {
    const subscription = dataModelRegistry
      .dataModelObservable()
      .subscribe(() => {
        setDataModels([...dataModelRegistry.getDataModels()]);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [dataModelRegistry]);

  const theme = useTheme();
  const configureCustomColumnStyled = useMemo(() => {
    return theme.getReviewEntriesTheme().dialogCreateCustomColumn;
  }, [theme]);

  const configureCustomOptionStyled = useMemo(() => {
    return theme.getReviewEntriesTheme().dialogCreateCustomOption;
  }, [theme]);

  return (
    <>
      <CreateNewColumnModal
        close={onCustomColumnClose}
        dataModels={dataModels}
        isOpen={isCustomColumnOpen}
        initialValues={columnInitialValues}
        type={typeCustomColumn ?? 'add'}
        configureStyled={configureCustomColumnStyled}
        onSubmit={(formValues) => {
          if (typeCustomColumn === 'add') {
            onAddColumn(
              formValues.columnName,
              formValues.columnType,
              formValues.dateFormat
            );
          } else if (typeCustomColumn === 'edit' && editingDataModel) {
            onEditColumn(editingDataModel, formValues.columnName);
          }
        }}
      />
      <CreateNewOptionModal
        close={onAddOptionClose}
        isOpen={isAddOptionOpen}
        dataModel={addingOptionDataModel}
        initialValues={optionInitialValues}
        configureStyled={configureCustomOptionStyled}
        onSubmit={(formValues) => {
          if (addingOptionDataModel) {
            onAddOption(
              addingOptionDataModel,
              formValues.optionName,
              formValues.optionType
            );
          }
        }}
      />
    </>
  );
};

export default CustomColumnAndOption;
