import useViewModel from './viewModel';
import {
  forwardRef,
  MutableRefObject,
  ReactNode,
  useImperativeHandle,
  useMemo,
} from 'react';
import { PopoverThemeProps, RecordInfo } from './type';
import HotTable from '@handsontable/react';
import { elementId } from './constant';
import StackedMessageView from '../Popover/StackedMessage';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/ReviewEntries/Loading';
import MenuItemDropdown from '../CellComponents/Editor/Dropdown/itemMenu';
import WrapperStyledTable from '../CellComponents/common/WrapperStyled';
import TopAction from './TopAction';
import { DataModel } from '../../dataModel/model/DataModel';
import DataModelSheet from '../model/DataModelSheet';
import { ColumnAPI } from '../../dataModel/columnsAPI';
import {
  HookedRecordResult,
  OnEntryChange,
  OnEntryInit,
} from '../../hooks/hooksAPI';
import { css, CSSInterpolation, cx } from '../../emotion';
import { Validator } from '../../reviewEntries/validator';
import { useTheme } from '../../../theme';
import { ConfirmModalProps } from './confirmModal';
import ContextMenu from './ContextMenu';
import { ReviewEntriesThemeAPI } from '../../../theme/themeAPI';
import CustomColumnAndOption from './CustomColumnAndOption';

const numOfEmptyRows = 1;

type DataModelSheetFormProps = {
  dataModels: DataModel[];
  onSubmit: (dataModelSheet: DataModelSheet) => void;
  hideShowErrorDropdown?: boolean;
  renderFooter?: ({
    handleSubmit,
    validating,
  }: RenderFooterParams) => ReactNode;
  onSubmitWhenInvalid?: (dataModelSheet: DataModelSheet) => void;
  configTheme?: {
    reviewEntriesTheme?: ReviewEntriesThemeAPI;
    popover?: PopoverThemeProps;
    popoverInfo?: CSSInterpolation;
  };
  dataInfos: Record<string, RecordInfo[]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: Record<string, any>[];
  handleChangeInfo: (
    entryChange: Record<string, HookedRecordResult>,
    rowIndex: number,
    sourceCol: string,
    dataModels: DataModel[]
  ) => void;
  validator: Validator;
  errorCount: MutableRefObject<number>;
  onValidateInitialFinish: (length: number) => void;
  disableExportHasMoreData: MutableRefObject<boolean>;
  columns: ColumnAPI[]; // NOTE: this is for initial, use dataModelRegistry for get columns
  isManualInput: boolean;
  updateTotalError: (errorCount: number) => void;
  htLicenseKey: string;
  identifier: string;
  baseColumns: ColumnAPI[];
  onEntryChange?: OnEntryChange;
  onEntryInit?: OnEntryInit;
  enableExamples?: boolean;
  modal?: boolean;
  toggleFullScreen: () => void;
  isFullScreen?: boolean;
  sendLog: (event: string) => Promise<void>;
  isBypass: boolean;
  setBypass: (value: boolean) => void;
  disabledMoreAction?: boolean;
  hidedFullScreenButton?: boolean;
  hidedExportButton?: boolean;
  wrapperStyle: string;
  getStyleTable: ({
    mediaSize,
    isXsLargeScreen,
    dataLength,
    numOfEmptyRows,
  }: {
    mediaSize: boolean;
    isXsLargeScreen: boolean;
    dataLength: number;
    numOfEmptyRows: number;
  }) => {
    height: string | number;
    maxHeight: string | number;
    minHeight: string;
  };
  removeRows?: number[];
  animateByKeyframe?: boolean;
  notValidate?: boolean;
  readOnly?: boolean;
  divWrapperClassName?: string;
  showRemove?: boolean;
  renderHeader?: () => ReactNode;
  disableTemplateDropdowns: boolean;
  rowsLimit?: number;
  showConfirmModal: (props: ConfirmModalProps) => void;
  smartTable: boolean;
  allowCustomColumns?: boolean;
};

type RenderFooterParams = {
  handleSubmit: (identifier?: string) => void;
  validating: boolean;
  errorCount: MutableRefObject<number>;
};

export type DataModelSheetFormRef = {
  exportValuesToXlsx: (ignoreContentDropdown: boolean) => void;
};

const hideVisibility = css`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

const DataModelSheetForm = forwardRef<
  DataModelSheetFormRef,
  DataModelSheetFormProps
>(
  (
    {
      dataModels: dataModelsColumn,
      onSubmit: onSubmitProp,
      renderFooter = () => null,
      hideShowErrorDropdown = false,
      onSubmitWhenInvalid,
      configTheme,
      dataInfos,
      dataSet,
      handleChangeInfo,
      validator,
      errorCount,
      onValidateInitialFinish,
      disableExportHasMoreData,
      columns,
      isManualInput,
      updateTotalError,
      modal,
      enableExamples,
      onEntryChange,
      onEntryInit,
      baseColumns,
      htLicenseKey,
      identifier,
      toggleFullScreen,
      isFullScreen,
      sendLog,
      isBypass,
      setBypass,
      hidedFullScreenButton = false,
      hidedExportButton = false,
      wrapperStyle,
      getStyleTable,
      removeRows,
      animateByKeyframe = true,
      notValidate,
      readOnly,
      divWrapperClassName,
      showRemove,
      renderHeader,
      disableTemplateDropdowns,
      rowsLimit,
      showConfirmModal,
      smartTable,
      allowCustomColumns,
    },
    forwardRef
  ) => {
    const {
      handleSubmit,
      exportValuesToXlsx,
      isSxlargeScreen,
      hotInstance,
      settingTable,
      changeModeView,
      popoverMessage,
      selectNextError,
      exporting,
      stackedMessagePopper,
      mediaSize,
      allowedRender,
      dropdownOptionsRef,
      onSelectOption,
      currentSelectorRef,
      currentEditingValueRef,
      optionsDropdown,
      searchValueRef,
      currentEditingModelRef,
      isLoadingDropdown,
      checkboxController,
      onRemove,
      onDuplicate,
      contextMenuController,
      parentTableElement,
      allColumnSetting,
      findDropdownOption,
      htCloneLeftWtHolderElement,
      onGetAllSearchMatchCount,
      onFindSearchMatch,
      onReplaceWordSearchMatch,
      onClearAllFilter,
      lastSelectedBySearchCell,
      modeViewTable,
      dataModelRegistry,
      deleteColumn,
      addColumn,
      addOption,
      customColumnUIObservable,
      customOptionUIObservable,
      waitingConfirmDeleteColumn,
      editColumn,
    } = useViewModel({
      dataModels: dataModelsColumn,
      onSubmit: onSubmitProp,
      onSubmitWhenInvalid,
      configTheme,
      dataInfos,
      dataSet,
      handleChangeInfo,
      validator,
      onValidateInitialFinish,
      errorCount,
      columns,
      isManualInput,
      updateTotalError,
      enableExamples,
      onEntryChange,
      onEntryInit,
      baseColumns,
      htLicenseKey,
      identifier,
      removeRows,
      notValidate,
      readOnly,
      rowsLimit,
      smartTable,
      allowCustomColumns,
      showConfirmModal,
    });

    const theme = useTheme();

    useImperativeHandle(forwardRef, () => {
      return {
        exportValuesToXlsx,
      };
    });

    const { t } = useTranslation();

    const styleTable = useMemo(() => {
      return getStyleTable({
        dataLength: dataSet.length,
        mediaSize,
        numOfEmptyRows,
        isXsLargeScreen: isSxlargeScreen,
      });
    }, [getStyleTable, dataSet.length, mediaSize, isSxlargeScreen]);

    const columnInfoPopper = useMemo(() => {
      return cx(
        css({
          maxWidth: 320,
          backgroundColor: theme.getGlobalTheme().getDark500Color(),
          borderColor: theme.getGlobalTheme().getDark500Color(),
        }),
        css({ '&&': configTheme?.popoverInfo })
      );
    }, [theme, configTheme?.popoverInfo]);

    return (
      <>
        {!allowedRender ? (
          <Loading
            initialProgress={0}
            timer={300}
            text={t('txt_validating')}
            notShowJustMoment={true}
            animateByKeyframe={animateByKeyframe}
            modal={modal}
          />
        ) : null}
        <CustomColumnAndOption
          hotInstance={hotInstance}
          customColumnUIObservable={customColumnUIObservable}
          customOptionUIObservable={customOptionUIObservable}
          dataModelRegistry={dataModelRegistry}
          onAddColumn={addColumn}
          onAddOption={addOption}
          onEditColumn={editColumn}
        />
        <div
          className={cx(
            'flex',
            modal ? 'h-full px-0' : 'px-6',
            divWrapperClassName,
            !allowedRender ? hideVisibility : ''
          )}
          style={{
            flexDirection: allowedRender ? 'column' : undefined,
          }}
        >
          {renderHeader ? renderHeader() : null}
          <WrapperStyledTable
            configTheme={configTheme}
            mediaSize={mediaSize}
            readOnly={readOnly}
            modal={modal}
            enableExamples={enableExamples}
            showRemove={showRemove}
          >
            <div
              id="data-model-sheet-form"
              className={!modal ? 'pb-4' : 'flex h-full min-h-0 flex-col'}
            >
              <TopAction
                changeModeView={changeModeView}
                hideShowErrorDropdown={hideShowErrorDropdown}
                exportValuesToXlsx={exportValuesToXlsx}
                validating={false}
                errorCount={errorCount}
                onSearchError={selectNextError}
                hasValidated={true}
                exporting={exporting}
                disableExportHasMoreDataRef={disableExportHasMoreData}
                isBypass={isBypass}
                sendLog={sendLog}
                setBypass={setBypass}
                toggleFullScreen={toggleFullScreen}
                isFullScreen={isFullScreen}
                hidedFullScreenButton={hidedFullScreenButton}
                hidedExportButton={hidedExportButton}
                modal={modal}
                disableTemplateDropdowns={disableTemplateDropdowns}
                checkboxController={checkboxController}
                onRemove={onRemove}
                onDuplicate={onDuplicate}
                showConfirmModal={showConfirmModal}
                smartTable={smartTable}
                onGetAllSearchMatchCount={onGetAllSearchMatchCount}
                onFindSearchMatch={onFindSearchMatch}
                onReplaceWordSearchMatch={onReplaceWordSearchMatch}
                dataModelRegistry={dataModelRegistry}
                contextMenuController={contextMenuController}
                onClearAllFilter={onClearAllFilter}
                allColumnSetting={allColumnSetting}
                readOnly={readOnly}
                lastSelectedBySearchCell={lastSelectedBySearchCell}
                modeViewTable={modeViewTable}
                hotInstance={hotInstance}
                dataSet={dataSet}
              />
              <div
                id={elementId.tableWrapperId}
                className={wrapperStyle}
                style={styleTable}
              >
                <HotTable
                  id="review-entries-table"
                  ref={hotInstance}
                  className="!text-sm"
                  settings={settingTable}
                />
              </div>
              <StackedMessageView
                messagesRef={popoverMessage}
                configTheme={configTheme?.popover}
                stackedMessagePopper={stackedMessagePopper}
                modal={modal}
              />
              <MenuItemDropdown
                onSelectOption={onSelectOption}
                currentEditingValueRef={currentEditingValueRef}
                currentEditingModelRef={currentEditingModelRef}
                optionsRef={dropdownOptionsRef}
                currentSelectorRef={currentSelectorRef}
                options={optionsDropdown}
                isShowSearchBar={optionsDropdown.length > 5}
                configTheme={configTheme?.reviewEntriesTheme?.edit?.category}
                searchValueRef={searchValueRef}
                isLoading={isLoadingDropdown}
                customOptionUIObservable={customOptionUIObservable}
              />
              <ContextMenu
                contextMenuController={contextMenuController}
                parentTableElement={parentTableElement}
                allColumnSetting={allColumnSetting}
                findDropdownOption={findDropdownOption}
                dataModelRegistry={dataModelRegistry}
                hotInstance={hotInstance}
                htCloneLeftWtHolderElement={htCloneLeftWtHolderElement}
                configTheme={configTheme}
                readOnly={readOnly}
                modal={modal}
                deleteColumn={deleteColumn}
                allowCustomColumns={allowCustomColumns}
                waitingConfirmDeleteColumn={waitingConfirmDeleteColumn}
                customColumnUIObservable={customColumnUIObservable}
                smartTable={smartTable}
              />
            </div>
            <div
              id={elementId.columnInfoElementId}
              className={cx(
                'popper-info animate-fade will-change-auto w-fit pointer-events-none z-50 hidden break-words rounded border py-2 px-3 text-left text-base text-white',
                columnInfoPopper
              )}
            ></div>
            {allowedRender &&
              renderFooter({
                handleSubmit,
                validating: false,
                errorCount: errorCount,
              })}
          </WrapperStyledTable>
        </div>
      </>
    );
  }
);

export default DataModelSheetForm;
