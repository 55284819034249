import { useSelect } from 'downshift';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icon/arrow-down-thin.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/icon/checked.svg';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import { CSSProperties, LegacyRef, RefObject, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { isNil } from 'lodash';
import { ModifierArguments, ModifierPhases } from '@popperjs/core';
import { cx, css, CSSInterpolation, CSSObject } from '../../../../core/emotion';
import { useTheme } from '../../../../theme';
import Portal from '../../../Portal';
import Popover from '../../../Popover';
import { isMobile, isTablet } from 'react-device-detect';

export type Option<T> = {
  label: string;
  value: T;
};

export type Action = {
  label: string;
  onClick?: () => void;
};

export type ValueEqual<T> = (value: T | null) => string | null;

const dropdownMenuShadowStyle = css`
  box-shadow: 0px 7px 15px rgba(27, 80, 113, 0.1);
`;

export type DropdownStyled = {
  root?: CSSInterpolation;
  button?: {
    root?: CSSInterpolation;
    icon?: CSSInterpolation;
    placeholder?: string;
  };
  scrollbar?: {
    navigatorColor?: string;
    backgroundColor?: string;
  };
  option?: CSSInterpolation;
  selectedOption?: CSSInterpolation;
  iconCheckedColor?: string;
};

type DropdownProps<T> = {
  className?: string;
  placeholder?: string;
  options: Option<T>[];
  onChange: (value: T | null) => void;
  value: T | null;
  disabled?: boolean;
  menuClassName?: string;
  valueEqual?: ValueEqual<T>;
  simpleBarClassName?: string;
  rootDropdownPortal?: HTMLDivElement;
  dropdownStyled?: DropdownStyled;
  showInfo?: boolean;
  infoMessage?: string;
  popoverStyled?: CSSInterpolation;
};

const itemToString = <T,>(item: Option<T> | null) => item?.label ?? '';

const Dropdown = <T,>({
  className,
  placeholder,
  options,
  onChange,
  value,
  disabled,
  menuClassName,
  valueEqual = (value) => (isNil(value) ? null : `${value}`),
  simpleBarClassName,
  rootDropdownPortal,
  dropdownStyled,
  showInfo,
  infoMessage,
  popoverStyled,
}: DropdownProps<T>) => {
  const [isShowInfo, setIsShowInfo] = useState(false);
  const getOnHover = () => {
    if (!isMobile && !isTablet) {
      return {
        onMouseEnter: () => setIsShowInfo(true),
        onMouseLeave: () => setIsShowInfo(false),
      };
    } else {
      return {
        onClick: () => setIsShowInfo((isShow) => !isShow),
        onMouseLeave: () => setIsShowInfo(false),
      };
    }
  };
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    itemToString,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem?.value ?? null);
    },
    selectedItem:
      options.find((item) => valueEqual(item.value) === valueEqual(value)) ??
      null,
    stateReducer: (_, actionAndChanges) => {
      const { changes } = actionAndChanges;
      return { ...changes, isOpen: disabled ? false : changes.isOpen };
    },
  });

  const { t } = useTranslation();

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const widthModifier = useMemo(() => {
    return {
      name: 'sameWidth',
      enabled: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fn: ({ state }: ModifierArguments<any>) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
      },
      phase: 'beforeWrite' as ModifierPhases,
      requires: ['computeStyles'],
    };
  }, []);
  const theme = useTheme();
  const hoverBorderColor = useMemo(() => {
    return theme.getGlobalTheme().getDark600Color();
  }, [theme]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      {
        name: 'flip',
        enabled: true,
        options: {
          padding: {
            bottom: 30,
          },
        },
      },
      widthModifier,
    ],
  });

  const scrollbarStyle = useMemo(
    () =>
      cx(
        css`
          max-height: 200px;
          .simplebar-track {
            width: 5px;
          }

          .simplebar-track.simplebar-vertical {
            margin: 0px 1px 0px 8px;
          }

          .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            top: 0px;
            bottom: 0px;
            width: 5px;
            left: 0;
            opacity: 1;
            background: #e1e1e1;
            border-radius: 25px;
          }
        `,
        css({
          '&& .simplebar-track.simplebar-vertical .simplebar-scrollbar:before':
            {
              backgroundColor: dropdownStyled?.scrollbar?.navigatorColor,
            },
          '&& .simplebar-track': {
            backgroundColor: dropdownStyled?.scrollbar?.backgroundColor,
          },
        })
      ),
    [dropdownStyled]
  );

  const dropdownMenuBorderColor = useMemo(() => {
    return theme.getGlobalTheme().getDark50Color();
  }, [theme]);

  return (
    <div
      className="relative"
      ref={setReferenceElement as LegacyRef<HTMLDivElement>}
    >
      <div className={cx('flex flex-col gap-1', className)}>
        <Popover
          isShowArrow
          arrowClassName={
            (popoverStyled as CSSProperties)?.['backgroundColor'] ?? '#545454'
          }
          arrowStyled={css`
            left: -113px !important;
          `}
          isShow={isShowInfo && (showInfo || false)}
          direction="bottom-start"
          offset={[0, 15]}
          message={
            <div
              style={{
                width: 240,
              }}
              className={cx(
                'rounded-medium leading-3.5 -mt-px bg-gray-700 py-3 px-4 text-center text-xs text-white',
                css({
                  '&&': popoverStyled,
                })
              )}
            >
              {infoMessage}
            </div>
          }
          render={({ referenceElement }) => {
            return (
              <button
                {...getOnHover()}
                aria-label="toggle menu"
                className={cx(
                  'rounded-medium border-1 relative flex h-9 w-full items-center justify-between p-3 text-sm focus:ring-0',
                  disabled ? 'bg-gray-120  cursor-not-allowed' : 'bg-white',
                  menuClassName,
                  selectedItem ? 'text-blue-dark-900' : 'text-gray-410',
                  selectedItem && !disabled
                    ? 'border-primary'
                    : 'border-gray-210',
                  !disabled
                    ? css({
                        '&&': dropdownStyled?.button?.root,
                        ':hover': {
                          borderColor: hoverBorderColor,
                        },
                      })
                    : '',
                  disabled
                    ? css({
                        '&&&': (dropdownStyled?.button?.root as CSSObject)?.[
                          ':disabled'
                        ],
                      })
                    : ''
                )}
                type="button"
                {...getToggleButtonProps({
                  ref: referenceElement as RefObject<HTMLButtonElement>,
                })}
              >
                <span
                  className={cx(
                    'flex-nowrap truncate',
                    css`
                      max-width: 95%;
                    `
                  )}
                >
                  {selectedItem ? t(itemToString(selectedItem)) : placeholder}
                </span>
                <span className="inline-block pl-1">
                  <ArrowDownIcon
                    className={cx(
                      'transform',
                      isOpen ? 'rotate-180' : '',
                      disabled
                        ? 'text-gray-410 cursor-not-allowed'
                        : 'text-blue-dark-900',
                      css({
                        '&&': {
                          color: (dropdownStyled?.button?.icon as CSSObject)
                            ?.color,
                        },
                      })
                    )}
                  />
                </span>
              </button>
            );
          }}
        />
      </div>
      <div>
        {isOpen ? (
          <Portal enable={true} root={rootDropdownPortal}>
            <div
              {...getMenuProps(
                {
                  ref: setPopperElement as LegacyRef<HTMLDivElement>,
                },
                { suppressRefError: true }
              )}
              className={cx(
                'rounded-medium border-1 absolute z-10 max-h-80 overflow-auto bg-white',
                dropdownMenuShadowStyle
              )}
              style={{ ...styles.popper, borderColor: dropdownMenuBorderColor }}
              {...attributes.popper}
            >
              <SimpleBar
                forceVisible={false}
                autoHide={true}
                id="code-snippet"
                className={cx(scrollbarStyle, simpleBarClassName)}
              >
                {options.map((item, index) => (
                  <div
                    className={cx(
                      highlightedIndex === index && 'bg-gray-50',
                      selectedItem === item
                        ? cx(
                            'bg-blue-light-50',
                            css({ '&&': dropdownStyled?.selectedOption })
                          )
                        : css({ '&&': dropdownStyled?.option }),
                      'py-10px text-neutral-gray flex cursor-pointer items-center pl-4 pr-3 text-sm'
                    )}
                    {...getItemProps({ item, index })}
                  >
                    <span className="flex-nowrap truncate">
                      {t(item.label)}
                    </span>
                    {selectedItem === item ? (
                      <CheckedIcon
                        className={cx(
                          'text-blue-light-500 ml-auto',
                          css({
                            '&&': {
                              color: dropdownStyled?.iconCheckedColor,
                            },
                          })
                        )}
                      />
                    ) : null}
                  </div>
                ))}
              </SimpleBar>
            </div>
          </Portal>
        ) : null}
      </div>
    </div>
  );
};

export default Dropdown;
