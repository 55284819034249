import { useTranslation } from 'react-i18next';
import { useNavigatePolicy } from 'navigation';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMainView, useSettings } from 'settings';
import { usePage } from 'main/MainView';
import SpreadSheetNavigate from '../../uploadData/SelectHeaderPage/SpreadSheetNavigate';
import {
  HEADER_SELECTION_PATH,
  MATCH_COLUMN_PATH,
  SHEET_SELECTION_PATH,
  UPLOAD_PAGE_PATH,
} from 'core/constants/route';
import { useScroll } from 'core/scroll';
import { SheetColumn } from 'sheetImporter';
import { JoinSheetColumn } from 'sheetImporter';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { sortSheetColumnBySheet } from './utils';
import useInAppNavigate from 'core/navigate';

const useViewModel = () => {
  const { state: locationState } = useLocation();
  const { t } = useTranslation();
  const state = locationState as {
    spreadSheetNavigate: SpreadSheetNavigate;
    hasBackStep?: boolean;
    dynamicUploadStart?: boolean;
  };
  const { automaticHeaderDetection, embedUploadArea = false } = useSettings();
  const { getHasSheetSelectionPage } = useNavigatePolicy();
  const { showConfirmModal } = useContextConfirmModalManager();
  const { modal } = useMainView();
  const { cancel } = usePage();
  const navigate = useInAppNavigate();
  const { scrollToTop } = useScroll();

  const initialSelectedSheetColumns = useMemo(() => {
    const sheetColumns: SheetColumn[] = [];
    state.spreadSheetNavigate
      .getSpreadSheetList()
      .getSelectedSheets()
      .forEach((sheet) => {
        const joinColumns = sheet.getJoinColumns();
        joinColumns.forEach((joinColumn) => {
          (joinColumn as JoinSheetColumn)
            .getJoinedSheetColumns()
            .forEach((sheetColumn) => {
              sheetColumns.push(sheetColumn);
            });
        });
      });

    return sheetColumns;
  }, [state]);

  const [selectedSheetColumns, setSelectedSheetColumns] = useState<
    SheetColumn[]
  >(initialSelectedSheetColumns);

  const sheets = useMemo(() => {
    return state.spreadSheetNavigate.getSpreadSheetList().getSelectedSheets();
  }, [state]);

  const [isCollapseItems, setIsCollapse] = useState<boolean[]>(
    sheets.map(() => true)
  );

  const [selectedPercentages, setSelectedPercentages] = useState<number[]>(
    sheets.map(() => 0)
  );

  const goToPreviousPage = () => {
    const spreadSheetNavigate = state.spreadSheetNavigate;
    if (spreadSheetNavigate) {
      spreadSheetNavigate.goToLastSpreadSheet();
      spreadSheetNavigate.goToLastSheet();

      if (automaticHeaderDetection) {
        if (
          !getHasSheetSelectionPage(spreadSheetNavigate.getSpreadSheetList())
        ) {
          if (embedUploadArea || !modal) {
            cancel();
          } else {
            cancel(false);
            navigate({ pathname: UPLOAD_PAGE_PATH });
          }
        } else {
          navigate(
            {
              pathname: SHEET_SELECTION_PATH,
            },
            {
              state: {
                spreadSheetList: spreadSheetNavigate.getSpreadSheetList(),
                hasBackStep: state?.hasBackStep,
                dynamicUploadStart: state?.dynamicUploadStart,
              },
            }
          );
        }
      } else {
        navigate(
          {
            pathname: HEADER_SELECTION_PATH,
          },
          {
            state: {
              spreadSheetNavigate,
              hasBackStep: state?.hasBackStep,
              dynamicUploadStart: state?.dynamicUploadStart,
            },
          }
        );
      }
    }
  };

  const onBackClick = () => {
    showConfirmModal({
      isShowIcon: true,
      title: t('txt_confirm_title'),
      description: t('txt_back_page_dialog'),
      textNegativeButton: t('txt_go_back'),
      textPositiveButton: t('txt_cancel'),
      onClickNegativeButton: () => {
        sheets.forEach((sheet) => {
          sheet.clearJoinColumns();
        });

        const spreadSheetNavigate = state.spreadSheetNavigate;
        if (spreadSheetNavigate) {
          scrollToTop();
          goToPreviousPage();
        } else {
          cancel();
        }
      },
    });
  };

  const onContinueClick = () => {
    const sheet = sheets[0];

    sheets.forEach((sheet) => {
      sheet.clearJoinColumns();
    });

    const sortedSelectedSheetColumns = sortSheetColumnBySheet(
      selectedSheetColumns,
      sheets
    ).filter((entry) => entry);

    if (
      sortedSelectedSheetColumns.length &&
      sheets.length === sortedSelectedSheetColumns.length &&
      selectedPercentages.every((selectedPercentage, index) => {
        if (index === 0) {
          return true;
        } else if (selectedPercentage > 0) {
          return true;
        } else {
          return false;
        }
      })
    ) {
      sheet.addJoinSheetColumn(sortedSelectedSheetColumns);
      navigate(
        {
          pathname: MATCH_COLUMN_PATH,
        },
        {
          state: {
            spreadSheetNavigate: state.spreadSheetNavigate,
            hasBackStep: state?.hasBackStep,
            dynamicUploadStart: state?.dynamicUploadStart,
          },
        }
      );
    } else if (
      selectedPercentages.some((entry) => entry === 0) &&
      sheets.length === sortedSelectedSheetColumns.length
    ) {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_no_matching_entry_title'),
        description:
          sortedSelectedSheetColumns.length > 2
            ? t('txt_no_matching_entry_description_multiple')
            : t('txt_no_matching_entry_description_single'),
        onClickNegativeButton: () => {},
        isShowPositiveButton: false,
        textNegativeButton: t('txt_close'),
      });
    } else {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_title_warning_join_column'),
        description: t('txt_desc_warning_join_column'),
        onClickNegativeButton: () => {},
        isShowPositiveButton: false,
        textNegativeButton: t('txt_close'),
      });
    }
  };

  const findLatestSelectedSheetColumnIndex = () => {
    let latestSelectedSheetColumnIndex = -1;

    const sheetIndexSelectedSheetColumns = selectedSheetColumns.map(
      (selectedSheetColumn) => {
        return sheets.findIndex(
          (sheetItem) => sheetItem === selectedSheetColumn.getSheet()
        );
      }
    );

    sheetIndexSelectedSheetColumns.forEach((sheetIndexSelectedSheetColumn) => {
      if (sheetIndexSelectedSheetColumn > latestSelectedSheetColumnIndex) {
        latestSelectedSheetColumnIndex = sheetIndexSelectedSheetColumn;
      }
    });

    return latestSelectedSheetColumnIndex;
  };

  const toggleCollapse = (cardIndex: number) => {
    setIsCollapse(
      isCollapseItems.map((item, index) => {
        if (index === cardIndex) {
          return !item;
        }
        return item;
      })
    );
  };

  const autoCollapse = (sheetIndex: number) => {
    setIsCollapse((isCollapseItems) =>
      isCollapseItems.map((isCollapseItem, index) => {
        if (index < sheetIndex && index > 0) {
          return false;
        } else {
          return isCollapseItem;
        }
      })
    );
  };

  const onSelectSheetColumn = (updatedSheetColumn: SheetColumn) => {
    const latestSelectedSheetColumnIndex = findLatestSelectedSheetColumnIndex();

    const updatedSheetColumnSheetColumnIndex = sheets.findIndex(
      (sheet) => sheet === updatedSheetColumn.getSheet()
    );

    if (
      updatedSheetColumnSheetColumnIndex - latestSelectedSheetColumnIndex <=
      1
    ) {
      const updatedSelectedSheetColumns: SheetColumn[] = [];

      for (let i = 0; i < selectedSheetColumns.length; ++i) {
        const sheet = selectedSheetColumns[i].getSheet();

        if (sheet !== updatedSheetColumn.getSheet()) {
          updatedSelectedSheetColumns.push(selectedSheetColumns[i]);
        }
      }

      updatedSelectedSheetColumns.push(updatedSheetColumn);

      if (
        updatedSheetColumnSheetColumnIndex - latestSelectedSheetColumnIndex ===
        1
      ) {
        autoCollapse(updatedSheetColumnSheetColumnIndex);
      }

      setSelectedSheetColumns(updatedSelectedSheetColumns);
    } else {
      const shouldSelectedSheetIndex = latestSelectedSheetColumnIndex + 1;
      const sheet = sheets[shouldSelectedSheetIndex];

      showConfirmModal({
        isShowIcon: true,
        title: t('txt_title_warning_join_column_order'),
        description: t('txt_desc_warning_join_column_order', {
          sheetName: sheet.getName(),
          fileName: sheet.getSpreadSheet().getFilename(),
        }),
        textNegativeButton: t('txt_submit_understand'),
        isShowPositiveButton: false,
        onClickNegativeButton: () => {},
      });
    }
  };

  const baseSheetColumn = useMemo(() => {
    return selectedSheetColumns.find(
      (selectedSheetColumn) => selectedSheetColumn.getSheet() === sheets[0]
    );
  }, [selectedSheetColumns, sheets]);

  const setSelectedPercentage = useCallback(
    (sheetIndex: number, percentage: number) => {
      setSelectedPercentages((selectedPercentages) => {
        const updatedSelectedPercentages = [...selectedPercentages];
        updatedSelectedPercentages[sheetIndex] = percentage;
        return updatedSelectedPercentages;
      });
    },
    []
  );

  return {
    onBackClick,
    onContinueClick,
    sheets,
    selectedSheetColumns,
    onSelectSheetColumn,
    baseSheetColumn,
    toggleCollapse,
    isCollapse: isCollapseItems,
    setSelectedPercentage,
  };
};

export default useViewModel;
