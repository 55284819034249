import FeatureWhiteList from '../../settings/FeatureWhiteList';
import {
  ColumnType,
  DataModelSheet,
  DataModelSheetMatch,
  DATATYPE,
} from '@nuvo-importer/common/sdk';
import { ColumnAPI } from '../../types';
import {
  ImportLogs,
  ImportLogsMapping,
  ImportLogsAddedColumn,
  ImportLogsAddedOptions,
  ImportLogsColumns,
} from '../hooksAPI';
import {
  CategoryDataModel,
  DataModel,
  Option,
} from '@nuvo-importer/common/core';

export const getImportLogs = (
  featureWhiteList: FeatureWhiteList,
  dataModelSheet: DataModelSheet
): ImportLogs => {
  const logSetting = featureWhiteList.getEnableLogs();
  if (!logSetting.columns && !logSetting.mappings) {
    return null;
  }

  const importLog: ImportLogs = {};

  if (logSetting.columns) {
    importLog.columns = getColumnsLogs(dataModelSheet);
  }

  if (logSetting.mappings) {
    importLog.mappings = getMappingsLogs(dataModelSheet);
  }

  return importLog;
};

export function getMappingsLogs(
  dataModelSheet: DataModelSheet
): ImportLogsMapping[] {
  const mappings: ImportLogsMapping[] = [];

  const matchedTargetColumns: Record<string, boolean> = {};

  const dataModelSheetMatch: DataModelSheetMatch[] =
    dataModelSheet.getMatchedColumns() ?? [];

  for (let i = 0; i < dataModelSheetMatch.length; i++) {
    const sourceColumn = dataModelSheetMatch[i].sheetColumn.getColumnKey();
    const targetColumn =
      dataModelSheetMatch[i].matchedDataModel?.dataModel?.getBaseKey();

    if (targetColumn) {
      matchedTargetColumns[targetColumn] = true;
    }

    mappings.push({
      sourceColumn,
      targetColumn: targetColumn || '',
    });
  }

  const baseColumns: ColumnAPI[] = dataModelSheet.getBaseColumns();

  baseColumns.forEach((column: ColumnAPI) => {
    const targetColumn: string = column.key;
    if (!matchedTargetColumns[targetColumn]) {
      mappings.push({
        sourceColumn: '',
        targetColumn,
      });
    }
  });

  return mappings;
}

export function getColumnsLogs(
  dataModelSheet: DataModelSheet
): ImportLogsColumns {
  const addedColumns: ImportLogsAddedColumn[] = [];
  const addedOptions: ImportLogsAddedOptions[] = [];

  const visitedColumnKeys: Record<string, number> = {};

  const dataModelSheetMatch: DataModelSheetMatch[] =
    dataModelSheet.getMatchedColumns() ?? [];

  for (let i = 0; i < dataModelSheetMatch.length; i++) {
    const match: DataModelSheetMatch = dataModelSheetMatch[i];
    const dataModel: DataModel | undefined = match.matchedDataModel?.dataModel;

    if (!dataModel) {
      continue;
    }

    // [Part 1]: Collect custom options
    const columnKey = dataModel.getBaseKey();

    if (dataModel.isCategoryType()) {
      const options: Option[] = (dataModel as CategoryDataModel).getOptions();
      for (let j = 0; j < options.length; j++) {
        if (options[j].creator !== 'manual') {
          continue;
        }

        if (visitedColumnKeys[columnKey] === undefined) {
          addedOptions.push({
            columnKey,
            dropdownOptions: [],
          });
          visitedColumnKeys[columnKey] = addedOptions.length - 1;
        }

        const index: number = visitedColumnKeys[columnKey];
        addedOptions[index].dropdownOptions.push({
          label: options[j].label,
          value:
            options[j].type === 'string' ? options[j].value : +options[j].value,
          type: options[j].type,
        });
      }
    }

    // [Part 2] Collect custom columns
    const creator: string | undefined = dataModel.getCreator();

    if (creator === undefined) {
      continue;
    }

    const column: ImportLogsAddedColumn = {
      key: dataModel.getKey(),
      label: dataModel.getLabel(),
      columnType: dataModel.getType() as ColumnType,
    };

    if (dataModel.getType() === DATATYPE.DATE) {
      column.outputFormat = dataModel.getOutputFormat();
    }

    addedColumns.push(column);
  }

  return {
    addedColumns,
    addedOptions,
  };
}
