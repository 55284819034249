import HotTable from '@handsontable/react';
import Button from '../../../../../components/Button';
import { ReactComponent as SearchIcon } from '../../../../../assets/icon/search-icon.svg';
import { css, cx } from '../../../../emotion';
import { MutableRefObject, RefObject } from 'react';
import useViewModel from './viewModel';
import Menu from './Menu';
import {
  DataModel,
  DataModelType,
} from '../../../../dataModel/model/DataModel';
import { NumberFormat } from '../../../../dataModel/columnsAPI';
import { Option } from '../../../../dataModel/model/CategoryDataModel';
import { useTranslation } from 'react-i18next';
import ContextMenuController from '../../ContextMenu/controller/ContextMenuController';
import { ReviewEntriesThemeAPI } from '../../../../../theme/themeAPI';
import { ConfirmModalProps } from '../../confirmModal';
import DataModelRegistry from '../../DataModelRegistry';

export type ISearchParams = {
  value: string;
  isExact: boolean;
  columns: IColumnInformation[];
  isReplaceAll?: boolean;
  wordToReplace?: string;
};

export type IColumnInformation = {
  key: string;
  type: DataModelType;
  isNumeric: boolean;
  numberFormat?: NumberFormat;
  options?: Option[];
  isMultiSelection?: boolean;
};

type SearchAndReplaceProps = {
  className: string;
  dataModels: DataModel[];
  onFindSearchMatch: (
    searchParams: ISearchParams,
    switchFocus?: boolean
  ) => void;
  onGetAllSearchMatchCount: (
    searchParams: ISearchParams
  ) => Promise<{ counter: number; hidden: number; skip: number } | undefined>;
  onReplaceWordSearchMatch: (searchParams: ISearchParams) => Promise<void>;
  contextMenuController: ContextMenuController;
  configTheme?: ReviewEntriesThemeAPI;
  readOnly?: boolean;
  lastSelectedBySearchCell: MutableRefObject<{
    row: number;
    col: number;
  } | null>;
  showConfirmModal: (props: ConfirmModalProps) => void;
  hotInstance: RefObject<HotTable>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: Record<string, any>[];
  dataModelRegistry: DataModelRegistry;
};

const SearchAndReplace = ({
  className,
  dataModels,
  onFindSearchMatch,
  onGetAllSearchMatchCount,
  onReplaceWordSearchMatch,
  contextMenuController,
  configTheme,
  readOnly,
  lastSelectedBySearchCell,
  showConfirmModal,
  hotInstance,
  dataSet,
  dataModelRegistry,
}: SearchAndReplaceProps) => {
  const { popper, isOpenMenu, setIsOpenMenu, setWaitingConfirmReplace } =
    useViewModel({
      contextMenuController,
    });
  const { t } = useTranslation();

  return (
    <div className={cx(className, 'relative')}>
      <span ref={popper.referenceElement as RefObject<HTMLSpanElement>}>
        <Button
          className={css({
            '&&': configTheme?.smartTable?.findAndReplaceButton,
          })}
          variant="secondary"
          onClick={() => {
            setIsOpenMenu(!isOpenMenu);
          }}
        >
          <span className="flex items-center">
            <SearchIcon className="mr-2" />{' '}
            {readOnly ? t('txt_find') : t('txt_find_and_replace')}
          </span>
        </Button>
      </span>
      <Menu
        isOpenMenu={isOpenMenu}
        setIsOpenMenu={setIsOpenMenu}
        popper={popper}
        dataModels={dataModels}
        onFindSearchMatch={onFindSearchMatch}
        onGetAllSearchMatchCount={onGetAllSearchMatchCount}
        onReplaceWordSearchMatch={onReplaceWordSearchMatch}
        configTheme={configTheme}
        readOnly={readOnly}
        lastSelectedBySearchCell={lastSelectedBySearchCell}
        showConfirmModal={showConfirmModal}
        setWaitingConfirmReplace={setWaitingConfirmReplace}
        hotInstance={hotInstance}
        dataSet={dataSet}
        dataModelRegistry={dataModelRegistry}
      />
    </div>
  );
};

export default SearchAndReplace;
