import { API_GATEWAY_TOKEN, API_URL } from 'core/constants/service';
import Rxios from 'core/services/Rxios';
import { map } from 'rxjs/operators';
import Tracking from '../../tracking/Tracking';
import { decode } from '../../core/base64Utils';
import { VerifyLicenseKeyResponse } from '../types';
import { Observable } from 'rxjs';

const API_PATH = {
  VERIFY: '/v3/license-keys/verify',
};

export class LicenseService extends Rxios {
  constructor() {
    super({
      baseURL: API_URL,
    });
  }

  public verifyLicenseKey = (
    licenseKey: string,
    language: string
  ): Observable<VerifyLicenseKeyResponse> => {
    return this.get<string>(
      API_PATH.VERIFY,
      { lang: language },
      {
        headers: {
          apikey: API_GATEWAY_TOKEN,
          Authorization: licenseKey,
          framework: Tracking.getFrameWork(),
          originRequest: Tracking.getOrigin(),
          versionNumber: Tracking.getVersionNumber(),
          frameworkVersion: Tracking.getFrameworkVersion(),
        },
        responseType: 'text',
      }
    ).pipe(
      map<string, VerifyLicenseKeyResponse>((res) => {
        const data = JSON.parse(
          decode(`${licenseKey}:${Tracking.getFrameWork()}`, res)
        ) as VerifyLicenseKeyResponse;
        return data;
      })
    );
  };
}
