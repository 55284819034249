import { css, cx } from 'core/emotion';
import { UseSelectPropGetters } from 'downshift';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@nuvo-importer/common/core';
import { VariableSizeList } from 'react-window';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import { Popper } from '../popper';
import { Option } from '../types';
import Container from './Container';
import SearchInput from '../../../../../common/Dropdown/SearchInput';
import { useTheme } from 'theme';
import chroma from 'chroma-js';
import {
  DropdownOptionProps,
  DropdownOptions,
} from '@nuvo-importer/common/sdk';
import { isArray, isEmpty } from 'lodash';
import { useIsShowSimilarity } from '../../../../../../../../settings/similarity';
import { CreateNewColumnOptionButton } from '@nuvo-importer/common';

const optionItemHeight = 39;

type MenuItemsProps = {
  popper: Popper;
  options: Option[];
  highlightedIndex: number;
  selectedItem: Option | Option[] | null;
  getItemProps: UseSelectPropGetters<Option>['getItemProps'];
  hasScrollbar: boolean;
  rootElement?: RefObject<HTMLDivElement>;
  configThemeMenuItem?: {
    root?: string;
    scrollbarNavigatorColor?: string;
    scrollbarBackgroundColor?: string;
    option?: string;
    selectedOption?: string;
    header?: string;
    search?: {
      root?: string;
      icon?: string;
      placeholder?: string;
    };
    createNewOption?: {
      root?: string;
      icon?: string;
    };
    multiSelectionBadge?: {
      root?: string;
      icon?: string;
    };
    iconCheckedColor?: string;
  };
  setSearchValue: (searchValue: string) => void;
  isShowSearch: boolean;
  isSearching: boolean;
  searchOptions: { option: Option; index: number }[];
  searchValue: string;
  isShowCustomOptions?: boolean;
  onCreateOptions?: () => void;
  setHighlightedIndex?: (index: number) => void;
  toggleDropdown: () => void;
  isMultiSelect: boolean;
};

const containerStyle = css`
  width: 393px;
`;

const hideListScrollbar = css`
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    box-shadow: none;
  }
`;

const MenuItems = ({
  popper,
  highlightedIndex,
  selectedItem,
  getItemProps,
  configThemeMenuItem,
  setSearchValue,
  isShowSearch = false,
  isSearching,
  searchOptions,
  searchValue,
  isShowCustomOptions,
  onCreateOptions,
  setHighlightedIndex,
  toggleDropdown,
  isMultiSelect,
  hasScrollbar,
  options,
}: MenuItemsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const searchHeaderTextRef = useRef<HTMLDivElement>(null);
  const [offsetHeightHeader, setOffsetHeightHeader] = useState(0);
  const listOptionsRef = useRef<VariableSizeList>(null);
  const isShowSimilarity = useIsShowSimilarity();

  const iconCheckedStyle = useMemo(() => {
    return configThemeMenuItem?.iconCheckedColor
      ? css`
          color: ${configThemeMenuItem?.iconCheckedColor};
        `
      : css({
          color: theme.getGlobalTheme().getNormal500Color(),
        });
  }, [configThemeMenuItem?.iconCheckedColor, theme]);

  const minWidthLg = useMediaQuery({
    query: `(min-width: ${breakpoints['4xl']})`,
  });

  const isShowHeader = useMemo(() => {
    return isShowSearch || isMultiSelect;
  }, [isShowSearch, isMultiSelect]);

  useEffect(() => {
    if (searchHeaderTextRef.current) {
      setOffsetHeightHeader(searchHeaderTextRef.current?.offsetHeight ?? 0);
      setTimeout(() => {
        listOptionsRef.current?.resetAfterIndex(0);
      });
    }
  }, [isSearching]);

  const selectedStyled = useMemo(() => {
    const normalPrimaryChroma = chroma(
      theme.getGlobalTheme().getPrimaryColor()
    );

    const color = chroma
      .hsl(
        normalPrimaryChroma.get('hsl.h') - 15.2941,
        normalPrimaryChroma.get('hsl.s') - 0.2226,
        normalPrimaryChroma.get('hsl.l') + 0.2921
      )
      .alpha(0.4)
      .hex();

    return cx(
      configThemeMenuItem?.selectedOption,
      css({ backgroundColor: color })
    );
  }, [theme, configThemeMenuItem?.selectedOption]);

  const optionsListHeight = useMemo(() => {
    const optionsListMaxHeight = minWidthLg ? 288 : 210;
    let baseHeight = optionItemHeight * searchOptions.length;
    if (isShowHeader) {
      baseHeight += offsetHeightHeader;
    }
    return Math.min(baseHeight, optionsListMaxHeight);
  }, [isShowHeader, searchOptions.length, offsetHeightHeader, minWidthLg]);

  const itemCount = useMemo(() => {
    if (isShowHeader) {
      return searchOptions.length + 1;
    } else {
      return searchOptions.length;
    }
  }, [searchOptions, isShowHeader]);

  const getItemSize = (index: number) => {
    if (isShowHeader && index === 0) {
      return offsetHeightHeader;
    } else {
      return optionItemHeight;
    }
  };

  return (
    <Container popper={popper}>
      <div
        className={cx(
          'rounded-medium dropdown-scroll w-full overflow-hidden border bg-white text-left',
          hasScrollbar ? '' : containerStyle,
          configThemeMenuItem?.root,
          configThemeMenuItem?.scrollbarNavigatorColor &&
            configThemeMenuItem.scrollbarNavigatorColor,
          configThemeMenuItem?.scrollbarBackgroundColor &&
            configThemeMenuItem.scrollbarBackgroundColor,
          css({
            borderColor: theme.getGlobalTheme().getDark50Color(),
          })
        )}
      >
        {isShowSearch ? (
          <SearchInput
            value={searchValue}
            onChange={(value) => setSearchValue(value)}
            theme={configThemeMenuItem?.search}
            headerTheme={configThemeMenuItem?.header}
          />
        ) : null}
        <SimpleBar
          style={{
            width: '100%',
            height: optionsListHeight,
          }}
          forceVisible={false}
          autoHide
          id="menuItems"
          className={cx(
            css`
              .simplebar-horizontal {
                height: 0px !important;
              }
            `
          )}
        >
          {({ scrollableNodeRef, contentNodeRef }) => {
            const isSelecting = (option: DropdownOptionProps) => {
              if (isMultiSelect) {
                if (isArray(selectedItem)) {
                  return selectedItem.includes(option);
                } else {
                  return false;
                }
              } else {
                return option === selectedItem;
              }
            };

            return (
              <DropdownOptions
                height={optionsListHeight}
                itemCount={itemCount}
                itemSize={getItemSize}
                innerRef={contentNodeRef}
                outerRef={scrollableNodeRef}
                className={hideListScrollbar}
                optionItemData={{
                  currentSelector: highlightedIndex,
                  isMultiSelect,
                  getItemProps: getItemProps,
                  onMouseLeave: () => setHighlightedIndex?.(-1),
                  isShowHeader,
                  isSearching,
                  searchHeaderTextRef,
                  checkedIconStyle: iconCheckedStyle,
                  configThemeHeader: configThemeMenuItem?.header,
                  selectedItemStyle: selectedStyled,
                  itemStyle: configThemeMenuItem?.option,
                  hoveredItemStyle: 'bg-priority-bg',
                  isSelecting,
                  items: searchOptions,
                  isShowSimilarity,
                }}
                ref={listOptionsRef}
              />
            );
          }}
        </SimpleBar>
        {(isSearching && searchOptions.length <= 0) || isEmpty(options) ? (
          <p className="block px-4 py-6 text-center text-sm capitalize italic text-gray-400">
            {t('txt_empty')}
          </p>
        ) : null}
        {isShowCustomOptions ? (
          <CreateNewColumnOptionButton
            label={t('txt_create_new_option')}
            configTheme={configThemeMenuItem?.createNewOption}
            onClick={() => {
              if (onCreateOptions) {
                onCreateOptions();
                toggleDropdown();
              }
            }}
          />
        ) : null}
      </div>
    </Container>
  );
};

export default MenuItems;
