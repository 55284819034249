import { Route, Routes } from 'react-router-dom';
import {
  SheetSelectionPage,
  SelectHeaderPage,
  UploadFilePage,
} from '../uploadData';
import MatchColumn from '../matchColumn';
import ReviewEntriesPage from '../reviewEntries/ReviewEntriesPage';
import Stepper from 'baseUI/Stepper';
import { useMainView } from 'settings';
import {
  HEADER_SELECTION_PATH,
  JOIN_COLUMN_PATH,
  MAIN_PAGE_PATH,
  MATCH_COLUMN_PATH,
  REVIEW_ENTRIES_PATH,
  SHEET_SELECTION_PATH,
  UPLOAD_PAGE_PATH,
} from 'core/constants/route';
import { useDevelopMode } from 'license';
import { css, cx } from 'core/emotion';
import { useTheme } from 'theme';
import { JoinColumnPage } from 'sheetImporter';
import useLocationPathname from 'core/location';
import { COLOR_SCROLL_BAR } from 'core/constants/colors';
import { useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useDomMutationSpy } from '../hooks/useDomMutationSpy';
import { NUVO_ELEMENT_ID } from 'core/constants/element';
import { useDevelopBanner } from '../hooks/useDevelopBanner';

const RouteList = () => {
  return (
    <Routes>
      <Route path={UPLOAD_PAGE_PATH} element={<UploadFilePage />} />
      <Route path={SHEET_SELECTION_PATH} element={<SheetSelectionPage />} />
      <Route path={HEADER_SELECTION_PATH} element={<SelectHeaderPage />} />
      <Route path={JOIN_COLUMN_PATH} element={<JoinColumnPage />} />
      <Route path={MATCH_COLUMN_PATH} element={<MatchColumn />} />
      <Route path={REVIEW_ENTRIES_PATH} element={<ReviewEntriesPage />} />
    </Routes>
  );
};

const Router = () => {
  const { pathname } = useLocationPathname();
  const { isDeveloperMode } = useDevelopMode();
  const mainView = useMainView();
  const theme = useTheme();
  const { state: locationState } = useLocation();

  const rootEleId = mainView.modal
    ? NUVO_ELEMENT_ID.MODAL_ROOT
    : NUVO_ELEMENT_ID.PAGE_ROOT;
  const { isVisible, forceListener } = useDomMutationSpy({
    rootViewId: rootEleId,
    rootTargetEleId: NUVO_ELEMENT_ID.DEV_BANNER_ROOT,
    targetEleId: NUVO_ELEMENT_ID.DEV_BANNER_TEXT,
    secondaryRootTargetEleId: NUVO_ELEMENT_ID.DEV_BANNER_ROOT_SUB_PAGE,
    identifier: mainView.identifier,
    isShowElement: isDeveloperMode,
  });

  const { renderDevelopBannerForSubPage, ensureDevelopBannerVisibility } =
    useDevelopBanner();

  const forceRerender = useCallback(() => {
    setTimeout(() => {
      if (!isDeveloperMode) return;
      const viewMode = mainView.modal
        ? 'modal'
        : pathname !== MAIN_PAGE_PATH
        ? 'subpage'
        : 'page';
      ensureDevelopBannerVisibility({
        isVisible,
        viewMode,
        isDeveloperMode,
        identifier: mainView.identifier,
        forceListener,
      });
    }, 0);
  }, [
    isDeveloperMode,
    isVisible,
    mainView.identifier,
    mainView.modal,
    pathname,
    forceListener,
    ensureDevelopBannerVisibility,
  ]);

  forceRerender();

  const renderStepper = useMemo(() => {
    const isShowDeveloperMode = isDeveloperMode && !mainView.modal;
    const noPadding = mainView.modal;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((locationState as any)?.hideStepper ?? false) {
      return isDeveloperMode ? null : <div className="my-8" />;
    }

    return (
      <div
        id={`${NUVO_ELEMENT_ID.STEPPER_ROOT}-${mainView.identifier}`}
        className={cx(
          noPadding ? '' : 'px-6',
          isShowDeveloperMode
            ? 'min-h-77 relative -mb-1'
            : 'mb-4 flex flex-col justify-between'
        )}
      >
        {renderDevelopBannerForSubPage(
          isShowDeveloperMode,
          mainView.identifier
        )}
        <Stepper
          className={cx(
            isShowDeveloperMode ? 'absolute bottom-0 !mb-4' : '!mb-0'
          )}
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isDeveloperMode, mainView.modal, locationState, isVisible]);

  if (mainView.modal) {
    return (
      <div
        id={NUVO_ELEMENT_ID.PAGE_WRAPPER}
        className="flex h-full w-full flex-col"
      >
        <div className="px-3 lg:px-8">
          {pathname !== MAIN_PAGE_PATH ? renderStepper : null}
        </div>
        <div
          className={cx(
            'flex-shrink flex-grow overflow-y-auto px-3 lg:px-8',
            css({
              '&&': css`
                ::-webkit-scrollbar {
                  -webkit-appearance: none;
                  width: 4px;
                }
                ::-webkit-scrollbar-track {
                  border-radius: 60px;
                  background-color: ${theme.getDialogTheme().scrollbar
                    .backgroundColor ?? COLOR_SCROLL_BAR.BACKGROUND};
                }
                ::-webkit-scrollbar-thumb {
                  border-radius: 60px;
                  background-color: ${theme.getDialogTheme().scrollbar
                    .navigatorColor ?? COLOR_SCROLL_BAR.NAVIGATOR};
                }
                scrollbar-width: thin;
                scrollbar-gutter: stable;
                scrollbar-color: ${theme.getDialogTheme().scrollbar
                    .navigatorColor ?? COLOR_SCROLL_BAR.NAVIGATOR}
                  ${theme.getDialogTheme().scrollbar.backgroundColor ??
                  COLOR_SCROLL_BAR.BACKGROUND};
              `,
            })
          )}
        >
          <RouteList />
        </div>
      </div>
    );
  } else {
    return (
      <div id={NUVO_ELEMENT_ID.PAGE_WRAPPER} className="w-full">
        {pathname !== MAIN_PAGE_PATH ? renderStepper : null}
        <RouteList />
      </div>
    );
  }
};

export default Router;
