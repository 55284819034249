import { FileRejection, FileWithPath } from 'react-dropzone';
import { CONFIG_ANIM_TIME } from '../core';
import { makeCancelable, PromiseCancelable } from '../core/promiseUtils';
import { SheetData, MultipleSheetData } from './SpreadSheetMapper';
import buildWorker from '../worker/importWorkerBundle.txt';
import { releaseProxy, wrap } from 'comlink';
import { isNil } from 'lodash';
import { createWorker } from 'core/worker/createWorker';
import { ERROR_CODE_PARSE_FILE } from '../errors/errorCode';
import jschardet from 'jschardet';

export type AdvancedParsingOptions = {
  advancedParsing: boolean;
  hasDateType?: boolean;
};

type Importer = {
  parseCSV: (result: string, hasDateType?: boolean) => SheetData;
  parseExcel: (result: ArrayBuffer, hasDateType?: boolean) => MultipleSheetData;
  parseXML: (result: string, opts: AdvancedParsingOptions) => MultipleSheetData;
  parseJSON: (result: string, opts: AdvancedParsingOptions) => SheetData;
  parseXLS: (result: ArrayBuffer, hasDateType?: boolean) => MultipleSheetData;
};

export const supportTypes = [
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'text/xml',
  'application/json',
  'text/tab-separated-values',
  '.tsv',
];

type SheetDataFile = {
  filename: string;
  type: string;
  data: SheetData;
  fileSize: number;
};

type MultipleSheetDataFile = {
  filename: string;
  type: string;
  data: MultipleSheetData;
  fileSize: number;
};

export type DataFile = SheetDataFile | MultipleSheetDataFile;

export const getErrorMessage = (errors: FileRejection[]) => {
  return errors[0]?.errors[1]?.message
    ? errors[0]?.errors[1]?.message
    : errors[0]?.errors[0]?.message;
};

const mappingErrorMessage: Record<string, string> = {
  'Too many files': 'txt_too_many_files_error',
};

export const getDescriptionTooManyFileError = (errorMessage: string) => {
  return mappingErrorMessage[errorMessage]
    ? mappingErrorMessage[errorMessage]
    : errorMessage;
};

export const accept = {
  'text/csv': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-excel': [],
  'text/xml': [],
  'application/json': [],
  'text/tab-separated-values': [],
  '.tsv': [],
};

/**
 * It converts a list of files into a list of JSON objects
 * @param {FileWithPath[]} targetFiles - FileWithPath[]
 * @returns A promise that resolves to an array of DataFile objects.
 */
export const convertFileToJson = (
  targetFiles: FileWithPath[],
  workerSetter: (worker: Worker) => void,
  opts: AdvancedParsingOptions,
  setTimeUpload?: (timeUpload: number) => void
): PromiseCancelable<DataFile[]> => {
  const baseTimeMS = 2.5;
  const baseFileSize = 2;
  return makeCancelable(
    Promise.all(
      targetFiles.map((targetFile) => {
        const { fileSize } = convertFileSize(
          Math.max(targetFile.size),
          targetFile.type
        );
        const timeUpload = ((fileSize * baseTimeMS) / baseFileSize) * 10;
        setTimeUpload?.(timeUpload);

        if (targetFile.type === supportTypes[0]) {
          return convertCsv2Json(targetFile, workerSetter, opts?.hasDateType);
        } else if (targetFile.type === supportTypes[3]) {
          return convertXML2Json(targetFile, workerSetter, opts);
        } else if (targetFile.type === supportTypes[4]) {
          return convertJson2Json(targetFile, workerSetter, opts);
        } else if (targetFile.type === supportTypes[2]) {
          return convertWorkSheetMs2Json(
            targetFile,
            workerSetter,
            opts?.hasDateType
          );
        } else {
          return convertWorkSheet2Json(
            targetFile,
            workerSetter,
            opts?.hasDateType
          );
        }
      }) as Promise<DataFile>[]
    )
  );
};

function convertFileSize(fileSize: number, type: string) {
  const size = Math.round(
    type === supportTypes[0] || type === supportTypes[4]
      ? 1
      : fileSize / 1000000
  );
  return { fileSize: size };
}

/**
 * It takes a file, converts it to text, then converts it to JSON
 * @param {FileWithPath} targetFile - FileWithPath - the file that you want to convert
 * @param workerSetter - (worker: Worker) => void
 * @param {string} errorUploadFileMessage - The error message to display if the file is not a valid XML
 * file.
 * @returns A promise that resolves to a MultipleSheetDataFile object.
 */
const convertXML2Json = (
  targetFile: FileWithPath,
  workerSetter: (worker: Worker) => void,
  opts: AdvancedParsingOptions
): Promise<MultipleSheetDataFile> => {
  const worker = createWorker(buildWorker);
  const exporter = wrap<Importer>(worker);
  worker && workerSetter(worker);

  return new Promise((resolve, reject) => {
    return targetFile
      .text()
      .then((result) => {
        return exporter.parseXML(result, opts).then((sheets) => {
          setTimeout(() => {
            resolve({
              filename: targetFile.name,
              type: targetFile.type,
              data: sheets,
              fileSize: targetFile.size,
            });
            exporter[releaseProxy]();
            worker.terminate();
          }, CONFIG_ANIM_TIME.ONE_SECOND);
        });
      })
      .catch((err) => {
        if (
          [
            ERROR_CODE_PARSE_FILE.FORMAT_ELEMENT,
            ERROR_CODE_PARSE_FILE.ADVANCED_PARSING_NOT_ALLOWED,
            ERROR_CODE_PARSE_FILE.FORMAT_ELEMENT,
          ].includes(err.error_code)
        ) {
          reject({
            code: err.error_code,
          });
        } else {
          reject({
            code: ERROR_CODE_PARSE_FILE.UNKNOWN,
          });
        }
        console.error(err);
        worker.terminate();
      });
  });
};

/**
 * It takes a file, reads it, converts it to JSON, and returns a promise with the converted data
 * @param {FileWithPath} targetFile - FileWithPath - this is the file that we want to convert to JSON.
 * @returns A promise that resolves to a SheetDataFile object.
 */
const convertCsv2Json = (
  targetFile: FileWithPath,
  workerSetter: (worker: Worker) => void,
  hasDateType?: boolean
): Promise<SheetDataFile> => {
  const worker = createWorker(buildWorker);
  const exporter = wrap<Importer>(worker);
  worker && workerSetter(worker);

  const fallbackConvertCsv = (
    resolve: (value: SheetDataFile | PromiseLike<SheetDataFile>) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reject: (reason?: any) => void
  ) => {
    targetFile
      .text()
      .then(async (result) => {
        const sheets = await exporter.parseCSV(result, hasDateType);
        setTimeout(() => {
          resolve({
            filename: targetFile.name,
            type: targetFile.type,
            data: sheets,
            fileSize: targetFile.size,
          });
          exporter[releaseProxy]();
          worker.terminate();
        }, CONFIG_ANIM_TIME.ONE_SECOND);
      })
      .catch((err) => {
        reject({
          code: ERROR_CODE_PARSE_FILE.UNKNOWN,
        });
        console.error(err);
        worker.terminate();
      });
  };

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const text = await targetFile.text();
      const fileInfo = jschardet.detect(text);
      if (fileInfo.encoding !== 'UTF-8' && !isNil(fileInfo.encoding)) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const arrayBuffer = e?.target?.result;
          const decoder = new TextDecoder(`${fileInfo.encoding}`);
          try {
            const text = decoder.decode(arrayBuffer as ArrayBuffer);
            const sheets = await exporter.parseCSV(text, hasDateType);
            setTimeout(() => {
              resolve({
                filename: targetFile.name,
                type: targetFile.type,
                data: sheets,
                fileSize: targetFile.size,
              });
              exporter[releaseProxy]();
              worker.terminate();
            }, CONFIG_ANIM_TIME.ONE_SECOND);
          } catch (error) {
            reject({
              code: ERROR_CODE_PARSE_FILE.UNKNOWN,
            });
            console.error(error);
            worker.terminate();
          }
        };

        reader.onerror = (error) => {
          reject({
            code: ERROR_CODE_PARSE_FILE.UNKNOWN,
          });
          console.error(error);
          worker.terminate();
        };
        reader.readAsArrayBuffer(targetFile);
      } else {
        fallbackConvertCsv(resolve, reject);
      }
    } catch (err) {
      fallbackConvertCsv(resolve, reject);
    }
  });
};

/**
 * It converts an XLS file into a JSON object
 * @param {FileWithPath} targetFile - FileWithPath
 * @returns A promise that resolves to a MultipleSheetDataFile
 */
const convertWorkSheetMs2Json = (
  targetFile: FileWithPath,
  workerSetter: (worker: Worker) => void,
  hasDateType?: boolean
): Promise<MultipleSheetDataFile> => {
  const worker = createWorker(buildWorker);
  const exporter = wrap<Importer>(worker);
  worker && workerSetter(worker);

  return new Promise((resolve, reject) => {
    return targetFile
      .arrayBuffer()
      .then((result) => {
        return exporter.parseXLS(result, hasDateType).then((sheets) => {
          setTimeout(() => {
            resolve({
              filename: targetFile.name,
              type: targetFile.type,
              data: sheets,
              fileSize: targetFile.size,
            });
            exporter[releaseProxy]();
            worker.terminate();
          }, CONFIG_ANIM_TIME.ONE_SECOND);
        });
      })
      .catch((err) => {
        reject({
          code: ERROR_CODE_PARSE_FILE.UNKNOWN,
        });
        console.error(err);
        worker.terminate();
      });
  });
};

/**
 * It converts an Excel file into a JSON object
 * @param {FileWithPath} targetFile - FileWithPath
 * @returns A promise that resolves to a MultipleSheetDataFile
 */
const convertWorkSheet2Json = (
  targetFile: FileWithPath,
  workerSetter: (worker: Worker) => void,
  hasDateType?: boolean
): Promise<MultipleSheetDataFile> => {
  const worker = createWorker(buildWorker);
  const exporter = wrap<Importer>(worker);
  worker && workerSetter(worker);

  return new Promise((resolve, reject) => {
    return targetFile
      .arrayBuffer()
      .then((result) => {
        return exporter.parseExcel(result, hasDateType).then((sheets) => {
          setTimeout(() => {
            resolve({
              filename: targetFile.name,
              type: targetFile.type,
              data: sheets,
              fileSize: targetFile.size,
            });
            exporter[releaseProxy]();
            worker.terminate();
          }, CONFIG_ANIM_TIME.ONE_SECOND);
        });
      })
      .catch((err) => {
        console.error(err);
        worker.terminate();
        convertWorkSheetMs2Json(targetFile, workerSetter, hasDateType)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.error(err);
            reject({
              code: ERROR_CODE_PARSE_FILE.UNKNOWN,
            });
          });
      });
  });
};

const convertJson2Json = (
  targetFile: FileWithPath,
  workerSetter: (worker: Worker) => void,
  opts: AdvancedParsingOptions
): Promise<SheetDataFile> => {
  const worker = createWorker(buildWorker);
  const exporter = wrap<Importer>(worker);
  worker && workerSetter(worker);

  return new Promise((resolve, reject) => {
    return targetFile
      .text()
      .then((result) => {
        return exporter.parseJSON(result, opts).then((sheets) => {
          setTimeout(() => {
            resolve({
              filename: targetFile.name,
              type: targetFile.type,
              data: sheets,
              fileSize: targetFile.size,
            });
            exporter[releaseProxy]();
            worker.terminate();
          }, CONFIG_ANIM_TIME.ONE_SECOND);
        });
      })
      .catch((err) => {
        if (
          [
            ERROR_CODE_PARSE_FILE.FORMAT_ELEMENT,
            ERROR_CODE_PARSE_FILE.ADVANCED_PARSING_NOT_ALLOWED,
            ERROR_CODE_PARSE_FILE.FORMAT_ELEMENT,
          ].includes(err.error_code)
        ) {
          reject({
            code: err.error_code,
          });
        } else {
          reject({
            code: ERROR_CODE_PARSE_FILE.UNKNOWN,
          });
        }
        worker.terminate();
      });
  });
};
